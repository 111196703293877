<template>
    <main class="container my-5">
      <div class="row">
        <div class="col-md-12 mx-auto">
        <div class="row col-12 page-head">
            <nav aria-label="breadcrumb" role="navigation">
            <ol class="breadcrumb page-head-nav">
                <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                <router-link :to="{ name: 'docker-images' }">Docker Images</router-link>
                </li>
                <li class="breadcrumb-item">
                Docker Image Update
                </li>
            </ol>
            </nav>
        </div>
          <form @submit.prevent="submitDockerImage">
            <div class="form-group">
              <label for>Docker Image Name</label>
              <input
                v-model="dockerImage.name"
                type="text"
                class="form-control"
                placeholder="Enter Docker Image Name"
                required
              />
            </div>
  
            <div class="form-group">
              <label for>Docker Common Identifier</label>
              <input
                v-model="dockerImage.common_identifier"
                type="text"
                class="form-control"
                placeholder="Enter Docker Common Identifier"
                required
              />
            </div>
  
            <div class="form-group">
              <label for>Docker Pull Command</label>
              <input
                v-model="dockerImage.pull_command"
                type="text"
                class="form-control"
                placeholder="Enter Docker Pull Command"
                required
              />
            </div>
  
            <div class="form-group">
              <label for>Docker Run Command</label>
              <input
                v-model="dockerImage.run_command"
                type="text"
                class="form-control"
                placeholder="Enter Docker Run Command"
                required
              />
            </div>

            <div class="form-group">
              <label for>Service Name</label>
              <input
                v-model="dockerImage.service_name"
                type="text"
                class="form-control"
                placeholder="Enter Docker Service Name"
                required
              />
            </div>
  
            <div class="form-group pt-2">
              <label for="selectImageType">Image Type</label>
              <select
                v-model="dockerImage.image_type"
                class="form-control"
                id="image_type"
                name="image_type"
                required
              >
                <option value="">Select Image Type</option>
                <option value="DEFAULT">DEFAULT</option>
                <option value="CUSTOM">CUSTOM</option>
              </select>
            </div>
  
            <div class="d-flex justify-content-center pt-3">
              <button
                class="btn btn-space btn-primary"
                :disabled="submitting"
                type="submit"
              >
                <span
                  v-if="submitting"
                  class="spinner-border spinner-border-sm"
                ></span>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  import axios from "axios";
  import router from "@/router";
  
  export default {
    name: "EditDockerImageForm",
    data() {
      return {
        dockerImage: {},
        submitting: false,
      };
    },
    mounted() {
      this.getDockerImage(this.$route.params.id);
    },
    methods: {
      async getDockerImage(id) {
        try {
          const response = await axios.get(`v1/docker-images/${id}/`);
          if (response.status === 200) {
            this.dockerImage = response.data;
          }
        } catch (error) {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        }
      },
      async submitDockerImage() {
        this.submitting = true;
        try {
          await axios.put(`v1/docker-images/${this.dockerImage.id}/`, this.dockerImage);
          this.$swal("Success", "Docker Image Updated Successfully", "success");
          router.push({ name: "docker-image-details", params: { id: this.dockerImage.id } });
        } catch (error) {
          this.$swal("Error", error.response?.data?.detail || "Something went wrong", "error");
        } finally {
          this.submitting = false;
        }
      },
    },
  };
  </script>
  
  <style scoped></style>
  